// Dependencies.
import React from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

// Components.
import {Header} from './header'
import './layout.css'

// Content.
const Content = styled.div`
  margin: 0 auto;
  max-width: 960;
  padding: 0 1.0875rem 1.45rem;
  padding-top: 0;
  text-align: center;
  background: #F4FFFC;
  min-height: 100vh;
  padding-bottom: 100px;
  max-width: 100vw;
  overflow-x: hidden;
`
const Footer = styled.footer`
  font-size: 14px;
  margin-top: 100px;
`

// Props.
interface Props {}

// Layout.
export const Page: React.FC<Props> = ({ children }) => {

  // Data.
  const data = useStaticQuery(graphql`query SiteTitleQuery {site {siteMetadata {title}}}`)

  // ..
  return <Content>

    {/* Main. */}
    <main style={{paddingBottom: '100px'}}>{children}</main>

    {/* Footer. */}
    <Footer>
      <div>
        An <a href="https://af.now.sh" target="_blank">Animal Free</a> initiative
      </div>
      <div>
        Illustrations by <a href="https://www.instagram.com/ryanamcshane/?hl=en" target="_blank">Ryan A. McShane</a>
      </div>
    </Footer>

  </Content>

}

export default Page
